import React, { useState } from 'react';
import {
  correctInternalLink,
  isItUndefined,
  isLinkAbsolute,
  modifyLinks,
} from '../../util/DataUtil';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Constants } from '../../util/Constants';
import { getEditorColor } from '../../util/ColorUtil';
import { cleanHTML } from '../utils/htmlUtils';

const FullWidthImageBanner = ({ data, lang }) => {
  const router = useRouter();
  const emphasizedTextColor = getEditorColor(
    isItUndefined(data?.field_title_color)
  );
  const [isHoverActive, setIsHoverActive] = useState(false);

  return (
    <div
      className={'a-full-width-image-banner-link full-width-image-banner'}
      onMouseOver={() => setIsHoverActive(!isHoverActive)}
      onMouseOut={() => setIsHoverActive(!isHoverActive)}
      style={{
        backgroundImage:
          'url(' +
          (Constants.cloudinaryImage + data?.field_full_width_image[0]) +
          ')',
      }}
      onClick={() => {
        if (isLinkAbsolute(data?.field_link[0]?.uri)) {
          window.open(data?.field_link[0]?.uri, '_blank');
        } else {
          router.push(correctInternalLink(data?.field_link[0]?.uri));
        }
      }}
    >
      <div className={'helper-left-margin-container'}>
        <span
          className={`title ${lang === 'de' ? 'title-de' : ''} ${lang === 'nl' ? 'title-nl' : ''} ${emphasizedTextColor ? emphasizedTextColor : ''}`}
          dangerouslySetInnerHTML={{
            __html: cleanHTML(modifyLinks(data?.field_title_color[0]?.value)),
          }}
        />
        {data?.field_link[0]?.uri && (
          <Link
            href={
              data?.field_link[0]?.uri
                ? correctInternalLink(data?.field_link[0]?.uri)
                : ''
            }
            passHref
            legacyBehavior
          >
            <a
              target={
                isLinkAbsolute(data?.field_link[0]?.uri) ? '_blank' : '_self'
              }
              className={`a-full-width-image-banner-link cta-link ${lang === 'fr' ? 'cta-link-fr' : lang === 'es' ? 'cta-link-es' : ''} ${emphasizedTextColor} ${isHoverActive ? ' hovered' : ''}`}
            >
              {data?.field_link[0]?.title}
            </a>
          </Link>
        )}
      </div>
    </div>
  );
};

export default FullWidthImageBanner;
